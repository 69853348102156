import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CampaignService } from 'src/services/Campaign.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, CampaignService],
  bootstrap: [AppComponent],
})
export class AppModule {

}

export function getAPIEndpoint()
{
  return 'https://postercar.com/api/public/';
}
export function getMapsAPIKey()
{
  return 'AIzaSyCOPDjnsxKOvO5oH1vOplmGSzp4eHNSSjM';
}