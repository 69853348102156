import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { getAPIEndpoint } from "src/app/app.module";

@Injectable()
export class CampaignService
{
    constructor(
        private http: HttpClient
    )
    {}

    public getPotentialCampaigns(lat, lng): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'campaign/applicable_for_customer', JSON.stringify({
                token: localStorage.getItem('pc_token'),
                lat: lat,
                lng: lng
            }))
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }

    public fetch(id): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'campaign/fetch', JSON.stringify({
                token: localStorage.getItem('pc_token'),
                id: id
            }))
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }

    public requestParticipation(fk_campaign): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'participation/request', JSON.stringify({
                token: localStorage.getItem('pc_token'),
                fk_campaign: fk_campaign
            }))
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }

    public checkEnrol(fk_campaign): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'participation/enrol/check', JSON.stringify({
                token: localStorage.getItem('pc_token'),
                fk_campaign: fk_campaign
            }))
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }

    public countEnrol(): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'participation/enrol/count', JSON.stringify({
                token: localStorage.getItem('pc_token')
            }))
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }

    public uploadPictureForCampaign(formData): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this.http.post(getAPIEndpoint() + 'participation/enrol/upload', formData)
                .subscribe
                (
                    (data: any) =>
                    {
                        resolve(data);
                    },
                    error =>
                    {
                        reject(error);
                    }
                );
        });
    }
}
